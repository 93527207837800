import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useInput} from "hooks/useInput";
import {useActions} from "hooks/useActions";
import {useDebounce} from "hooks/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import openGame from "helpers/gamesHelper/openGame";
import {useTypedSelector} from "hooks/useTypedSelector";
import GameCard from "ui/GameCard";
import {useTranslation} from 'react-i18next';
import TextField from "../ui/material-kit/TextField";
import SearchIcon from "../assets/images/input_search_icon.svg";
import Ball from "assets/images/loader-ball-icon.png"
import useDimensions from "react-cool-dimensions";
import AccordionSelect from "../ui/AccordionSelect";
import {getStorage} from "../hooks/useStorage";
import RulesModal from "../components/modal/RulesModal";
import {useSearchParams} from "react-router-dom";


const TvGamesDesktop = (): JSX.Element => {
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialize, setInitialize] = useState(true);
    const [titleGame, setTitleGame] = useState("");
    const {toggleFavoriteGames, updateAlert, setLoading, getTvGames} = useActions();
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [selectProviderCard, setSelectProviderCard] = useState<string>("");
    const [page, setPage] = useState(1);
    const [rulesModal, setRulesModal] = useState(false);
    const debouncedSearchTerm = useDebounce(titleGame, 500);
    const searchProvider = useInput('');

    const TvGamesAccess = getStorage("APP_ACCESS")["TV_GAMES"];

    const {user, tvGames, tvGamesCount, tvProviders} = useTypedSelector((state) => {
        return {
            user: state.user.user,
            tvProviders: state.game.tvProviders,
            isLoading: state.game.loading,
            tvGamesCount: state.game.tvGamesCount,
            tvGames: state.game.tvGames.toArray().map(item => item[1]),
        }
    })


    const getProviders = () => {
        const providersList = tvProviders.map((item) => ({
            title: item, onClick: () => {
                if (item === selectProviderCard) {
                    setSearchParams({...Object.fromEntries(searchParams), provider: ""});
                    setSelectProviderCard("");
                } else {
                    setSearchParams({...Object.fromEntries(searchParams), provider: item});
                    setSelectProviderCard(item);
                }
            },
            isSelect: item === selectProviderCard,
        }));

        return providersList.filter(item => item.title.toLocaleLowerCase().includes(searchProvider.value.toLocaleLowerCase()))
    }

    useEffect(() => {
        if (initialize) {
            let params = {
                provider: searchParams.get("provider"),
                title: searchParams.get("title")
            }
            let isSearch = Object.values(params).some(item => item !== null);
            if (isSearch) {
                setSelectProviderCard((params.provider === null || !getProviders().find(item => (item.title === params.provider))) ? "" : params.provider);
                setTitleGame(params.title === null ? "" : params.title);
            }
            getTvGames({
                title: params.title === null ? "" : params.title,
                action: "update",
                size: 30,
                page: 1,
                provider: params.provider === null ? selectProviderCard : params.provider,
            });
            setTimeout(() => {
                setRulesModal(true);
            }, 500);

            setInitialize(false);
        } else {
            window.scrollTo({
                top: 0,
            })
            handleSearch().then(() => {
                setPage(1);
                setHasMoreItems(true);
            })
        }

    }, [debouncedSearchTerm, selectProviderCard])


    const handleSearch = async () => {
        await getTvGames({
            title: debouncedSearchTerm,
            action: "update",
            size: 30,
            provider: selectProviderCard,
        });
    }

    const loadMore = () => {
        if (tvGames.length >= tvGamesCount) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getTvGames({
            title: titleGame,
            provider: selectProviderCard,
            action: "add",
            size: 30,
            page: newPage
        })
    };

    const {observe, width} = useDimensions({});
    return (

        <>
            <Wrapper>
                <StickyContainer>
                    <CategoryContainer>
                        <InputContainer>
                            <TextField placeholder={`${t(`common.options.search_game`)}`} rightIcon={{
                                url: SearchIcon,
                                extra: `width: 16px; height: 16px;`
                            }} extra={`height: 43px; font-size: 18px`}
                                       value={titleGame} onChange={(e) => {
                                setSearchParams({...Object.fromEntries(searchParams), title: e.target.value});
                                setTitleGame(e.target.value)
                            }}/>
                        </InputContainer>
                        <InputContainer>
                            <InputContainerAbsolute>
                                <AccordionSelect {...searchProvider}
                                                 list={getProviders()}
                                                 isImage={false}
                                                 title={`${selectProviderCard === "" ? `${t(`common.options.provider_filter`)}` : selectProviderCard}`}
                                                 withSearch/>
                            </InputContainerAbsolute>
                        </InputContainer>
                    </CategoryContainer>
                </StickyContainer>

                <GameContainer ref={observe}>
                    <InfiniteScroll
                        dataLength={tvGames.length}
                        next={loadMore}
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexFlow: "wrap",
                            rowGap: 20,
                            columnGap: 20
                        }}
                        hasMore={hasMoreItems && tvGames.length !== 0}
                        loader={<ContainerLoader>
                            <Loader src={Ball}/>
                        </ContainerLoader>}
                    >
                        {
                            tvGames.map((item, index) => (
                                <React.Fragment key={`game-desktop-${index}-${item.Id}`}>
                                    <GameCard
                                        isTvGame
                                        TvGameWidth={`${(width - 70) / 4}px`}
                                        TvGameHeight={`auto`}
                                        favoriteClick={(e) => {
                                            if (!user) {
                                                updateAlert({
                                                    isOpen: true,
                                                    status: "error",
                                                    message: `${t(`common.alert.text_error`)}`
                                                });
                                            } else {
                                                e.stopPropagation();
                                                toggleFavoriteGames(item.Id, false, true);
                                            }
                                        }} isFavorite={item.IsFavorite} url={item.Thumbnail} onClick={() => {
                                        if (!user) {
                                            updateAlert({
                                                isOpen: true,
                                                status: "error",
                                                message: `${t(`common.alert.text_error`)}`
                                            });
                                        } else {
                                            openGame({
                                                isDesktop: true,
                                                providerId: item.SectionId,
                                                gameId: item.Id,
                                                setLoading: (bool: boolean) => {
                                                    setLoading(bool);
                                                }
                                            })
                                        }
                                    }}/>
                                </React.Fragment>
                            ))
                        }

                    </InfiniteScroll>
                </GameContainer>

            </Wrapper>

            {
                TvGamesAccess.PERMISSIONS.RULES_MODAL &&
                <RulesModal isDesktop isOpen={rulesModal} onClose={() => setRulesModal(false)}/>
            }

        </>
    );
};

export default TvGamesDesktop;


const Wrapper = styled.div`
  margin: 20px 80px 0 80px;
`

const CategoryContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.palette.primary.dark};
  width: 100%;
  display: flex;
  height: 60px;
  justify-content: space-around;
  align-items: center;
  
`

const StickyContainer = styled.div`
  top: 0;
  position: sticky;
  width: 100%;
  z-index: 2000;
  background-color: ${props => props.theme.palette.primary.dark};
`



const InputContainer = styled.div`
  width: 300px;
  height: 43px;
  position: relative;
`

const InputContainerAbsolute = styled(InputContainer)`
  position: absolute;
  z-index: 9999;
`


const ContainerLoader = styled.div`
  width: 340px;
  height: 215px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Loader = styled.img`
  width: 75px;
  height: 75px;
  animation: load 1.5s linear infinite;
`

const GameContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  background-color: ${props => props.theme.palette.primary.dark};
  width: 100%;
`
