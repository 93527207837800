import React from 'react';
import styled from "styled-components";
import config, {additionalCards} from "../config/cards-menu.config";
import {MenuCard} from "ui/adCard/menu-card";
import {TawkService} from "services/tawkService";
import {unionArraysByKey} from "helpers/unionArraysByKey";
import {MainMenuCard} from "../types/card.types";
import {useActions} from "../../../hooks/useActions";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

type CardsMenuProps = {
    isDesktop?: boolean,
    className?: string,
}

export const CardsMenu = (props: CardsMenuProps) => {
    const {isDesktop = false, className} = props;
    const cardsData = config;
    const navigate = useNavigate();
    const fullData: MainMenuCard[] = unionArraysByKey(cardsData, additionalCards, 'KEY');
    const {t} = useTranslation();
    const {updateAlert} = useActions();
    const cardHelp: MainMenuCard = {
        KEY: 'help',
        VISIBLE: true,
        PATH: '/help',
        DESKTOP_PATH: '/help',
        GRID: '6/7',
        arrowColor: '#DBFF00',
        shadowArrow: 'drop-shadow(0px 2px 10px #35964B)',
        withLineBackground: true,
        gradientColor: 'radial-gradient(52.08% 65.51% at 50% 31.57%, rgba(57, 152, 74, 0.5) 0%, rgba(4, 5, 24, 0.5) 100%), linear-gradient(180deg, #5F750B 9.15%, #070D1F 80.43%)',
    }
    const openAlert = () => {
        updateAlert({isOpen: true, status: "warning", message: `${t(`common.alert.will_be_soon`)}`})
    }
    return (
        <Content className={className}>
            {
                fullData.slice(0, isDesktop ? 5 : 6).map((item, index) => (
                    <React.Fragment key={`ad-cards-${index}-${item.KEY}`}>
                        <MenuCard card={item} onClick={() => {
                            if (item.PATH === "") {
                                openAlert();
                            } else {
                                navigate(isDesktop ? item.DESKTOP_PATH : item.PATH);
                                window.scrollTo(0, 0);
                            }
                        }}/>
                    </React.Fragment>
                ))
            }
            {!isDesktop && <React.Fragment key={`ad-cards-help`}>
                <MenuCard card={cardHelp} onClick={() => {
                    TawkService.openWidget();
                }}/>
            </React.Fragment>}
        </Content>
    );
};

const Content = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, 1fr);


`

