import React from 'react';
import styled from "styled-components";
import {Container} from 'ui/containers';
import {CardsMenu} from "../features/cards-menu";

const Main = (): JSX.Element => {

    return (
        <Wrapper>
            <CardsMenu/>
        </Wrapper>

    );
};

export default Main;



const Wrapper = styled(Container)`
  padding: 0px 7px;
  margin-top: 70px;
`
