import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import styled from "styled-components";
import {useTypedSelector} from "hooks/useTypedSelector";
import {useActions} from 'hooks/useActions';
import Header from 'components/wrapper/header';
import Footer from 'components/wrapper/footer';
import ScrollButton from 'components/scrollButton';
import Sport from "pages/Sport";
import StaticPage from "pages/StaticPage";
import {useSocket} from "services/SocketService";
import Toolbar from 'ui/material-kit/Toolbar';
import {createGlobalStyle} from 'styled-components';
import {getStorage} from "../hooks/useStorage";
import getCorrectRoutes from "../helpers/getCorrectRoutes";
import SingIn from "../pages/SingIn";
import {useTranslation} from "react-i18next";


const MobileApp = () => {
    const {updateModal, updateAlert} = useActions();
    const {t, i18n} = useTranslation();
    const {pathname} = useLocation();
    const socket = useSocket();
    const [betBy, setBetBy] = useState<any>(null);
    const isOurPage = [`/horses`, `/sport`, `/live-sport`].includes(pathname);
    const isProfile = pathname.includes(`/profile`) || pathname.includes(`/promotions`) || pathname.includes(`/static`);
    const [openDrawer, setOpenDrawer] = useState(false);
    const languages = getStorage("APP_OTHER.LANGUAGES");
    const access = getStorage("APP_ACCESS");
    const isAccessLoginPage = access.LOGIN_PAGE.VISIBLE;
    const isAccessSport = access.SPORT.VISIBLE;
    const isAccessLiveSport = access.LIVE_SPORT.VISIBLE;
    const isAccessStaticPages = access.STATIC_PAGES.VISIBLE;

    const {user, pages} = useTypedSelector((state => {
        return {
            pages: state.app.pages,
            user: state.user.user
        }
    }))

    let isAuth = user !== null;

    useEffect(() => {
        if (isAccessSport || isAccessLiveSport) {
            let b = document.getElementById("betby");
            if (pathname !== "/sport" && pathname !== "/live-sport") {
                b!.hidden = true;
                if (betBy) {
                    betBy.clean()
                }

            } else {
                b!.hidden = false
            }
        }
    }, [pathname])


    const lang: any = Object.values(languages)!.find((it: any) => it.KEY === i18n.language);

    const GlobalStyle = createGlobalStyle`
      body {
        color: white;
        direction: ${lang?.DIRECTION};
        background: ${
                isProfile ? "#121931" : "#000000"
        }
      }
    `;

    useEffect(() => {
        socket.on('connect', () => {
            console.log("connection successful")
        })
        socket.on('notification', (pld) => {
            const {type, content} = pld;
            const curContent = content.find((item: any) => item.lang === i18n.language);
            updateAlert({isOpen: true, status: type === "success" ? "warning" : "error", message: curContent.message})
        })
    }, [])


    return (
        <>
            <GlobalStyle/>
            {!isAuth && isAccessLoginPage ? <>
                    <Routes>
                        <Route path={`/*`} element={<SingIn/>}/>
                        <Route path={`/*`} element={<Navigate to={`/`}/>}/>
                    </Routes>
                </> :
                <>
                    {![`/horses`].includes(pathname) && <Header setOpenLogin={() => updateModal(true)}
                                                                user={user} isOpenDrawer={openDrawer}
                                                                setOpenDrawer={(open) => setOpenDrawer(open)}/>}

                    {![`/horses`].includes(pathname) && <Toolbar/>}
                    <Routes>
                        {getCorrectRoutes(isAuth, ["SPORT", "LIVE_SPORT", "STATIC_PAGES"])}
                        {isAccessSport &&
                            <Route path={`/sport`}
                                   element={<Sport type={"sport"} setBetBy={setBetBy} betBy={betBy}/>}/>}
                        {isAccessLiveSport && <Route path={`/live-sport`}
                                                     element={<Sport type={"live-sport"} setBetBy={setBetBy}
                                                                     betBy={betBy}/>}/>}


                        {isAccessStaticPages &&
                            pages.map((item, index) => (
                                <React.Fragment key={`${index}-${item.id}`}>
                                    <Route path={`/static/${item.url}/${item.lang}`}
                                           element={<StaticPage id={item.id}/>}/>
                                </React.Fragment>
                            ))
                        }

                        <Route path={`/*`} element={<Navigate to={`/`}/>}/>
                    </Routes>
                    {!isOurPage &&
                        <>
                            <ScrollButton/>
                            <BottomContainer/>
                            <Footer isOpenDrawer={openDrawer}/>
                        </>
                    }
                </>
            }
        </>
    );
};

export default MobileApp;

const BottomContainer = styled.div`
  padding-bottom: 56px
`
