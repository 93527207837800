import React, {useEffect, useState} from 'react';
import Portal from 'services/PortalService';
import styled from "styled-components";
import {Star} from "./star";
import {Ticker} from "./ticker";
import {ReactComponent as DropDownIcon} from "../assets/down_arrow.svg";
import {devices} from "../../../constants/breakpoints";
import {useHighRollerMutation} from "../model/high-roller.store";

export const HighRoller = () => {
    const [open, setOpen] = useState(false);
    const [getHighRollers] = useHighRollerMutation();

    useEffect(() => {
        getHighRollers().then();
    }, [getHighRollers]);

    return (
        <Portal id="high-roller-root">
            <PortalWrapper open={open}>
                <HighRollerString open={open}>
                    <Star/>
                    <Ticker open={open} onClose={() => {
                        setOpen(false)
                    }}/>
                    <DropDownButton open={open} onClick={() => {
                        setOpen(!open)
                    }}/>
                </HighRollerString>
            </PortalWrapper>

        </Portal>
    );
};

const DropDownButton = styled(DropDownIcon)<{ open: boolean }>`
  rotate: ${({open}) => open ? `180deg` : `0deg`};
  padding: 5px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 5px;
  z-index: 1000;

  :hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  :active {
    opacity: 0.8;
  }
`

const PortalWrapper = styled.div<{ open: boolean }>`
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  outline: 0;
  z-index: 1000;

  ${devices.tablet} {
    bottom: 56px;
  }

`

const HighRollerString = styled.div<{ open: boolean }>`
  background: #060912;
  width: 100%;
  z-index: 1000;
  position: relative;
  overflow: ${({open}) => open ? "visible" : "hidden"};
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-overflow-scrolling: {
    diplay: none;
  };
`

