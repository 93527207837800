import {gamesAPI} from "api/games/gamesApi";
import axios from "axios";
import { updateError } from "store/actions/appActions";
import {store} from "../../store";
import {t} from "i18next";
import i18n from "i18n";
const {dispatch} = store;
interface IOpenGame {
    providerId?: string,
    isDesktop?: boolean,
    isSport?: boolean,
    sportType?: string,
    gameId?: string,
    setLoading: (bool: boolean) => void
}

const openGame = ({providerId, gameId, isSport, sportType, setLoading, isDesktop}: IOpenGame) => {
    let prevUrl = window.location.href;
    if (isDesktop) {
        localStorage.setItem("previousPage", prevUrl);
        window.location.href = `/game/${providerId}/${gameId}`;
        return
    }
    setLoading(true);
    if (!isSport) {
        axios.post(`/api/json-rpc/create-session`, {
            GameId: gameId,
            ProviderId: providerId,
            ReturnUrl: prevUrl,
            Device: "mobile",
            Lang: i18n.language
        }).then((pld) => {
            let {data} = pld;
            window.location.href = data.SessionUrl
            setLoading(false);
        }).catch(error => {
            if (error.response.data.code === 265) {
                dispatch(updateError({isOpen: true, message: t("common.errors.this_provider_is_not_available_in_your_country")}))
            } else {
               dispatch(updateError({isOpen: true, message: t("common.errors.something_went_wrong")}))
            }
            setLoading(false);
        })
    } else {
        if (!sportType){
            return
        }
        gamesAPI.getSportToken().then(x => {
            let url = ""
            if (sportType === "live") {
                url = "https://sport-frame.koora365.tn/Sport/live"
            } else {
                url = "https://sport-frame.koora365.tn/Sport/sport"
            }
            window.location.href = `${url}?token=${x.token}`;
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            let {data} = error.response;
        })

    }


};

export default openGame;
