import React, {useEffect, useState} from 'react';
import CustomChip from "../CustomChip";
import {ReactComponent as SuccessIcon} from "assets/images/chip_success_icon.svg";
import {ReactComponent as ErrorIcon} from "assets/images/chip_error_icon.svg";
import {ReactComponent as CloseIcon} from "assets/images/alert_close_icon.svg";
import useInterval from "hooks/useInterval";
import {useActions} from 'hooks/useActions';
import {useTypedSelector} from 'hooks/useTypedSelector';
import styled from 'styled-components';
import Portal from 'services/PortalService';
import {CSSTransition} from 'react-transition-group';
import { useTranslation } from 'react-i18next';

type CustomAlertProps = {
    isDesktop?: boolean,
}

const Alert = (props: CustomAlertProps) => {
    const [play, setPlay] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProgress, setAlertProgress] = useState(0);
    const {i18n, t} = useTranslation();
    const {updateAlert} = useActions();

    const {alert} = useTypedSelector((state => {
        return {
            alert: state.app.alert,
        }
    }))

    const handleClearAlert = () => {
        updateAlert({
            ...alert,
            isOpen: false,
        })
    }


    let settings = {
        color: alert.status === "warning" ? "#FFB401" : "#C01414",
        iconJSX: alert.status === "warning" ? <SuccessIcon/> : <ErrorIcon/>,
        text: alert.status === "warning" ?  t("common.alert.type_success") : t("common.alert.type_error"),
    }

    useEffect(() => {
        if (alert.isOpen) {
            setOpenAlert(true)
            setAlertProgress(0);
            setPlay(true);
        } else {
            handleClose();
        }

    }, [alert.isOpen]);


    useInterval(() => {
        if (alertProgress !== 100) {
            setAlertProgress(alertProgress + 2)
        } else {
            setPlay(false);
            handleClose();
        }
    }, play ? 100 : null)

    const handleClose = () => {
        handleClearAlert();
        setOpenAlert(false);
        setAlertProgress(0);
    }

    return (
        <CSSTransition
            in={openAlert}
            timeout={200}
            classNames="alert"
            unmountOnExit
        >
            <Portal id="alert-root">
                <Wrapper id={`custom-alert`} isDesktop={props.isDesktop} onClick={() => handleClose()}>
                    <Background isDesktop={props.isDesktop} width={alertProgress}/>
                    <UselessLine color={settings.color}/>
                    <InfoContainer color={settings.color} isDesktop={props.isDesktop}>
                        <CustomChip isDesktop={props.isDesktop} text={settings.text} color={settings.color}
                                    iconJSX={settings.iconJSX}/>
                        <Text style={i18n.language === "fr" ?  !props.isDesktop ? {fontSize: 10} : {fontSize: 20} : undefined } isDesktop={props.isDesktop}>
                            {`${alert.message}`}
                        </Text>
                        <CloseIcon/>
                    </InfoContainer>
                </Wrapper>
            </Portal>
        </CSSTransition>

    );
};

export default Alert;


const Text = styled(`div`)<{ isDesktop?: boolean }>((props) => ({
    color: "#ffffff",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    fontWeight: 500,
    fontSize: !props.isDesktop ? 15 : 28,
    "@media (max-width: 306px)": {fontSize: 12}

}));

const Background = styled(`div`)<{ width: number, isDesktop?: boolean }>((props) => ({
    width: !props.isDesktop ? 306 : 600,
    backgroundColor: "#ffffff",
    opacity: 0.2,
    position: "absolute",
    top: 0,
    left: !props.isDesktop ? -306 + props.width * 3.2 : -600 + props.width * 6.5,
    transition: "left .6s linear",
    height: "100%"
}));


const UselessLine = styled(`div`)<{ color: string }>((props) => ({
    background: props.color,
    width: "100%",
    height: 7,
    borderRadius: 5,
}));

const InfoContainer = styled(`div`)<{ color: string, isDesktop?: boolean }>((props) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "6px 4px 4px 4px",
    "& > svg > path": {
        fill: props.color,
        width: !props.isDesktop ? 16 : 30,
        height: !props.isDesktop ? 16 : 30
    },
    "& > svg": {
        width: !props.isDesktop ? 16 : 30,
        height: !props.isDesktop ? 16 : 30
    },

}));


const Wrapper = styled(`div`)<{isDesktop?: boolean }>((props) => ({
    width: !props.isDesktop ? 306 : 600,
    height: !props.isDesktop ? 45 : 65,
    cursor: "pointer",
    background: props.theme.palette.primary.main,
    borderRadius: 5,
    boxSizing: "border-box",
    overflow: "hidden",
    position: "fixed",
    top: !props.isDesktop ? 7 : 138,
    right: 0,
    display: "flex",
    flexDirection: "column",
    zIndex: 99999999,


    "&:hover": {
        background: "#1d2641",
    }

}));
