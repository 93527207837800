import LanguageController from 'components/language';
import {ReactComponent as GiftIcon} from "assets/images/header_gift_icon.svg";
import StarIcon from 'assets/images/star.png';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Navbar from './NavBar';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import formatDate from "../../../helpers/formatDate";
import SocialLinks from "../../../ui/SocialLinks";
import {getStorage} from "../../../hooks/useStorage";
import useTime from 'hooks/useTime';

type SecondHeaderDesktopProps = {
    isAuthUser: boolean,
}

const SecondHeaderDesktop = (props: SecondHeaderDesktopProps) => {
    const navigate = useNavigate();
    const {i18n, t} = useTranslation();
    const time = useTime();

    const APP_ACCESS = getStorage("APP_ACCESS");
    const APP_OTHER = getStorage("APP_OTHER");
    const SOCIAL_LINKS = APP_OTHER.SOCIAL_LINKS;
    const socialLinksArr: any = Object.values(SOCIAL_LINKS).filter((item: any) => item.VISIBLE);

    return (
        <Wrapper>
            <Navbar isAuthUser={props.isAuthUser}/>
            <RightContainer>
                {socialLinksArr.length !== 0 && <SocialLinks isDesktop/>}
                {APP_ACCESS.PROMOTIONS.VISIBLE && <ButtonContainer onClick={() => {
                    navigate("/promotions");
                }}>
                    <GiftIcon/>
                </ButtonContainer>}
                <HallOfFame onClick={() => {
                    navigate("/hall-of-fame");
                }}>
                    <HallOfFameImages src={StarIcon}/>
                    <HallOfFameText>
                        {t(`pages.hall_of_fame.top`)}
                    </HallOfFameText>
                </HallOfFame>
                <TextContainer>
                    {`${formatDate({
                        date: time,
                        dateFormat: 'iiii dd.MM.yyyy HH:mm',
                        lang: i18n.language
                    })}`}
                </TextContainer>
                <LanguageContainer>
                    <LanguageController isDesktop={true}/>
                </LanguageContainer>
            </RightContainer>
        </Wrapper>
    );
};

export default SecondHeaderDesktop;


const HallOfFame = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  cursor: pointer;
  :hover {
    scale: 1.2;
  }
`

const HallOfFameText = styled.div`
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-weight: 700;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #4D2608;

`
const HallOfFameImages = styled.img`
  position: absolute;
  object-fit: contain;
  width: 70px;
  height: 70px;
  scale: 1.1;
`

const ButtonContainer = styled.div`
  width: 60px;
  height: 50px;
  border-radius: 5px;
  background: conic-gradient(from 0deg at 50% 50%, #FFB401 0deg, #FFED4F 360deg);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: conic-gradient(from 0deg at 50% 50%, #dc9e02 0deg, #d9c745 360deg);
  }

  :active {
    background: conic-gradient(from 0deg at 50% 50%, #c59003 0deg, #b9a93d 360deg);
  }
`

const Wrapper = styled.div`
  height: 50px;
  background: ${props => props.theme.palette.primary.dark};
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  justify-content: space-between;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 21px;
`

const TextContainer = styled.div`
  font-family: Roboto Condensed;
  font-weight: bold;
  font-size: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 1540px) {
    font-size: 18px;
  }
`
const LanguageContainer = styled.div`
  margin-left: -13px;
`
